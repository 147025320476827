import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.wrapper = {
      width: '100%',
    };

    this.swiper = {
      '.swiper-container': {
        cursor: ['grab', 'auto'],
        pl: ['16px', 'unset'],
        overflow: 'visible',
      },
      '.swiper-container, .swiper-wrapper': {
        height: 'auto !important',
        width: '100%',
      },
      '.swiper-slide': {
        opacity: 0.35,
      },
      '.swiper-slide-visible': {
        opacity: 1,
      },
      '.swiper-button-prev': {
        left: 'auto',
        right: '56px',
        top: ['-75px', null, null, '-66px'],
        color: 'black',
        width: '36px',
        height: '36px',
        '@media only screen and (max-width: 767px)': {
          display: 'none',
        },
      },
      '.swiper-button-prev:after': {
        fontSize: '30px',
      },
      '.swiper-button-next': {
        left: 'auto',
        right: 0,
        top: ['-75px', null, null, '-66px'],
        color: 'black',
        width: '36px',
        height: '36px',
        '@media only screen and (max-width: 767px)': {
          display: 'none',
        },
      },
      '.no-heading .swiper-button-next, .no-heading .swiper-button-prev': {
        top: ['-40px', null, null, '-40px'],
      },
      '.swiper-button-next:after': {
        fontSize: '30px',
      },
    };

    this.button = {
      mt: [12, 14],
    };
  })()
);

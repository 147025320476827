import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.shell = {
      bg: 'transparent',
      left: 0,
      position: 'absolute',
      display: ['block', null, 'none'],
      top: [0, 'calc(var(--header-height))'],
      width: ['100%', '375px'],
      height: [
        'var(--viewport-height)',
        `calc(var(--viewport-height) - var(--header-height) - var(--promoBar-height, 0px))`,
      ],
      willChange: 'transform',
      transition: 'transform .3s ease-in-out',
      zIndex: 1000,
      pointerEvents: 'none',
      overflowX: 'hidden',
    };

    this.scroll = {
      pointerEvents: 'auto',
      bg: 'background',
      mt: ['calc(var(--header-height))', 0],
      height: [
        `calc(var(--viewport-height) - var(--header-height))`,
        `calc(var(--viewport-height) - var(--header-height) - var(--promoBar-height, 0px))`,
      ],
      overflowY: 'scroll',
      webkitOverflowScrolling: 'touch',
      msOverflowStyle: 'none',
      scrollbarWidth: 'none',
      '::-webkit-scrollbar': {
        display: 'none',
      },
    };

    this.sidebar = {
      pb: '28px',

      search: {
        px: 8,
        pt: 10,
        pb: '28px',
        borderBottom: (t) => `1px solid ${t.colors.gray}`,
        position: 'relative',
        '.ss-Powered': {
          fontSize: '11px',
          position: 'absolute',
          right: 8,
          bottom: 2,
          color: '#565656',
        },
      },

      items: {
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridGap: '28px',
      },
    };
  })()
);

import { useEffect, useState } from 'react';
import { Container, Box } from 'theme-ui';

import { ProductSwiper } from '@snippets';
import { themed } from './Slider.theme';

export const Slider = themed(({ theme, products, loop = true, swiperClassName }) => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const count = products?.length >= 4;

  return (
    <Container data-comp={Slider.displayName} sx={theme.wrapper}>
      <Box sx={theme.swiper}>
        {mounted && (
          <ProductSwiper
            isProductRow
            swiperClassName={swiperClassName}
            hideAddToCart={false}
            products={products}
            count={count}
            loop={loop}
          />
        )}
      </Box>
    </Container>
  );
});

Slider.displayName = 'Slider';

import { Box, Grid, Paragraph } from 'theme-ui';
import { useSettings } from '@backpackjs/storefront';

import { SearchInput } from '@snippets';

import { Links } from './Links';
import { ProductsRow } from './ProductsRow';
import { SubNav } from './SubNav';
import { ReferLink } from './ReferLink';
import { themed } from './MenuSidebar.theme';

/**
 * Renders the mobile nav and links
 */
export const MenuSidebar = themed(({ theme }) => {
  const settings = useSettings();
  const { header } = { ...settings };

  return (
    <Box data-comp={MenuSidebar.displayName} sx={theme.sidebar}>
      <Box data-comp="MenuSidebarSearchInput" sx={theme.sidebar.search}>
        <SearchInput />
        <Paragraph className="ss-Powered">Powered by SearchSpring</Paragraph>
      </Box>

      <Grid sx={theme.sidebar.items}>
        {/* Menu items with sub links (accordion) */}
        <SubNav items={header?.menu} />

        <ProductsRow
          heading={header?.bestSellers?.heading}
          products={header?.bestSellers?.products}
        />

        <Links links={header?.links} />
      </Grid>
      <ReferLink />
    </Box>
  );
});

MenuSidebar.displayName = 'MenuSidebar';

import Script from 'next/script';
import { useEffect } from 'react';
import { useCartId, useCartUpdateAttributes } from '@backpackjs/storefront';
import { useAnalytics, TRACKING_EVENTS } from './hooks';

const fueledScriptUrl =
  process.env.NEXT_PUBLIC_FUELED_SCRIPT_URL ||
  'https://mb-cdn.fueled.io/fueled/53616c7465645f5fd48ca0d5f8e061369be440200484413c5366ef0cf6ba0777842400cdb07e71ccc0847fcb50ac4b30c6ffe17880e4620a0943a5a3e666d3cd/packdigital/js';

export function FueledClientScript({ consent }) {
  const cartId = useCartId();
  const { cartUpdateAttributes } = useCartUpdateAttributes();
  const { register, subscribe } = useAnalytics();
  const ready = register('fueled');

  // accumulate events until the fueled script is loaded
  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.packdigital = window.dataLayer?.packdigital || [];

    const eventHandler = (event) => {
      window.dataLayer.packdigital.push({
        type: event.type,
        detail: event.detail,
      });
    };

    Object.values(TRACKING_EVENTS).forEach((eventType) => {
      subscribe(eventType, eventHandler);
    });

    ready();
  }, []);

  useEffect(() => {
    if (!cartId) return () => null;

    let cleanCartId = cartId.replace('gid://shopify/Cart/', '');
    // eslint-disable-next-line prefer-destructuring
    cleanCartId = cleanCartId.split('?')[0]; // Remove any trailing query strings added to the cartId.

    const setCartToken = async () => {
      const fueled = window.fueled || {};

      const res = await fueled.setFrontendStorage(
        fueled.config.frontendStorageUrl,
        cleanCartId
      );

      const {
        context: {
          ga4: { clientId = undefined, sessionId = undefined } = {},
          fb: { fbp = undefined, fbc = undefined } = {},
        },
      } = await res.json();

      await cartUpdateAttributes({
        attributes: [
          {
            key: 'fueled_context',
            value: JSON.stringify({
              ga4: { clientId, sessionId },
              fb: { fbp, fbc },
            }),
          },
        ],
      });
    };

    if (window.fueled) {
      setCartToken();
    } else {
      window.addEventListener('fueled-ready', setCartToken);
    }

    return () => {
      window.removeEventListener('fueled-ready', setCartToken);
    };
  }, [cartId]);

  if (!consent?.marketing) return null;

  return <Script type="text/javascript" src={fueledScriptUrl} />;
}

import { useEffect, useRef, useState, useMemo } from 'react';
import { useCustomer } from '@backpackjs/storefront';
import { useKlaviyoApi } from '@hooks/useKlaviyoApi';
import { Container, Heading, Button } from 'theme-ui';
import { Markdown } from '@snippets';
import { useQuizContext } from './QuizContext';
import { InputField } from '../../templates/account/Shared/InputField';

export function QuizMailGate() {
  const form = useRef();
  const email = useRef();
  const stickyFooter = useRef();
  const quizStepsContainer = useRef();

  const context = useQuizContext();
  const customer = useCustomer();
  const {
    quizSettings,
    quizData,
    updateQuizData,
    getResultsProducts,
    getQAs,
    theme,
  } = context;
  const {
    mailGateKlaviyoId,
    title,
    description,
    mailGateSubmitText,
    mailGateCTAText,
    klaviyoProperties,
  } = quizSettings.mailGate;

  const [hasError, setHasError] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const { subscribeToList, sendEvent } = useKlaviyoApi();

  const resultProducts = getResultsProducts();
  const qas = getQAs();

  const profileProperties = useMemo(() => {
    let resultIndex = 0;
    const properties = resultProducts?.reduce((acc, result) => {
      if (result.product?.data?.title) {
        resultIndex += 1;
        return {
          ...acc,
          [`Quiz Result ${resultIndex} - Product Name`]: `${result.product?.data?.title}`,
          [`Quiz Result ${resultIndex} - Product ID`]:
            result.product?.id.split('/Product/')[1],
        };
      }
      return acc;
    }, {});

    if (klaviyoProperties && klaviyoProperties?.length > 0 && qas?.length > 0) {
      let messIndex = 0;
      klaviyoProperties?.forEach((item) => {
        const selected = qas.find(
          (qa) => qa.question.indexOf(item.propertyKey?.trim()) > -1
        );
        if (selected) {
          messIndex += 1;
          properties[`Message ${messIndex} = ${item.propertyKey?.trim()}`] =
            selected.answer;
        }
      });
    }

    return properties;
  }, [resultProducts, klaviyoProperties, qas]);

  const quizSubscribe = async (e) => {
    e.preventDefault();

    setIsSubmitted(true);

    const emailElement = document.querySelector('input[name="quiz-email"]');
    const nameElement = document.querySelector('input[name="quiz-name"]');

    await subscribeToList({
      listId: mailGateKlaviyoId,
      email: emailElement?.value,
      name: nameElement?.value,
      source: 'Quiz Mail Gate',
    });

    await sendEvent({
      eventName: 'Quiz Mail Gate Results',
      metricName: 'Quiz Save Results',
      email: emailElement?.value,
      name: nameElement?.value,
      profileProperties,
    }).then((response) => {
      if (response.errors) {
        setHasError(true);
      } else {
        setTimeout(() => {
          updateQuizData({
            quizStep: 'results',
          });
        }, 1500);
      }
    });
  };

  const isFooterSticky = () => {
    if (quizStepsContainer.current && stickyFooter.current) {
      if (quizStepsContainer.current.getBoundingClientRect().bottom > 50) {
        // Passed the Quiz section
        stickyFooter.current.classList.remove('hidden');
      } else {
        // In the Quiz section
        stickyFooter.current.classList.add('hidden');
      }
    }
  };

  // Sticky Menu Area
  useEffect(() => {
    window.addEventListener('scroll', isFooterSticky);
    return () => {
      window.removeEventListener('scroll', isFooterSticky);
    };
  });

  return (
    <Container
      className="min-h-[620px] flex flex-col py-7 md:py-20 lg:py-28 px-5 max-w-[1240px] mx-auto text-center"
      ref={quizStepsContainer}
    >
      {isSubmitted && (
        <div className="flex flex-col items-center justify-center">
          <Heading variant="h2">Calculating Your Results....</Heading>
          <div className="mt-16" sx={theme?.loader} />
        </div>
      )}

      {!isSubmitted && (
        <>
          {/* Mail Gate Header */}
          <div className="max-w-2xl mx-auto">
            {title && <Heading variant="h2">{title}</Heading>}

            {description && <Markdown className="!mt-6" text={description} />}
          </div>

          {/* Mail Gate Form */}
          <form
            className="mt-6 max-w-[312px] mx-auto w-full"
            ref={form}
            id="subscribeForm"
            onSubmit={quizSubscribe}
          >
            <InputField
              type="text"
              name="quiz-name"
              label=""
              placeholder="Your Name"
              sx={{
                fontSize: '15px',
                py: '10px',
                mb: '12px',
              }}
              required
            />

            <InputField
              type="email"
              name="quiz-email"
              label=""
              placeholder="Your Email"
              ref={email}
              value={customer?.email}
              sx={{
                fontSize: '15px',
                py: '10px',
                mb: '12px',
              }}
              onChange={() => {
                if (hasError) {
                  setHasError(false);
                }
              }}
              required
            />
          </form>

          {/* Mail Gate Footer */}
          <div
            className="md:!block mt-auto fixed md:static left-0 bottom-0 w-full p-5 bg-white z-[29]"
            ref={stickyFooter}
          >
            <div className="flex items-center justify-center gap-4">
              <Button
                variant="buttons.secondary"
                className="mt-4 !inline-block !w-full !min-w-max md:!w-[100px]"
                onClick={() => {
                  updateQuizData({
                    quizStep: 'steps',
                    hasError: false,
                  });
                }}
              >
                Back
              </Button>

              <Button
                variant="buttons.primary"
                className="mt-4 !inline-block !min-w-[184px] !w-auto"
                form="subscribeForm"
                type="submit"
              >
                {mailGateSubmitText}
              </Button>
            </div>

            {mailGateCTAText && (
              <Button
                variant="buttons.articleLink"
                onClick={() => {
                  setIsSubmitted(true);
                  setTimeout(() => {
                    updateQuizData({
                      quizStep: 'results',
                    });
                  }, 1500);
                }}
                className="!mt-4 !text-[14px] md:!text-[15px]"
                aria-label="Skip to the next step"
              >
                {mailGateCTAText}
              </Button>
            )}
          </div>
        </>
      )}
    </Container>
  );
}

import { useState, useEffect } from 'react';
import Script from 'next/script';

function Libs() {
  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    if (window) {
      window._shipScout = window._shipScout || [];
      /**
      * commented it out because it overrides the Shopify object and breaks the Shopify Privacy library
      window.Shopify = window.Shopify || {};
      window.Shopify.shop =
        process.env.SHOPIFY_DOMAIN || 'kryo-gear.myshopify.com';
      */
      window.shipScoutCookieDomain = window.location.host;
    }
    setMounted(true);
  }, []);
  return (
    <>
      <Script
        id="url-params-cookie-script"
        strategy="beforeInteractive"
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `
            (function () {
              const maxAge = '7776000';
              const getDomain = (url) => {
                const domainParts = url.split('.');
                return domainParts.length > 2 ? domainParts.slice(-2).join('.') : url;
              };
              const getSubdomainIndex = (url) => {
                if (!url) return 1;
                const parts = url.split(".");
                if (parts[0] === 'www') return 1;
                return parts.length === 1 ? 0 : parts.length === 2 ? 1 : 2;
              };
              const subdomainIndex = getSubdomainIndex(window.location.host);
              const generateFbc = (fbclid) => 'fb.' + subdomainIndex + '.' + Date.now() + '.' + fbclid;

              const fbclid = new URLSearchParams(window.location.search).get('fbclid');
              if (fbclid) {
                const fbc = generateFbc(fbclid);
                document.cookie = '_fbc=' + fbc + '; path=/; domain=.' + getDomain(window.location.hostname) + '; max-age=' + maxAge + '; samesite=lax';
              }
            })();`,
        }}
      />
      <style>{`.async-hide { opacity: 0 !important}`}</style>
      {mounted && (
        <Script
          type="text/javascript"
          id="shipscout-script"
          strategy="afterInteractive"
          src="https://web.shipscout.app/app.min.js"
        />
      )}
      <Script
        type="text/javascript"
        src="https://cdn.searchspring.net/intellisuggest/is.min.js"
      />
      <Script
        src="https://js.afterpay.com/afterpay-1.x.js"
        strategy="beforeInteractive"
      />
      <Script
        id="tapcart-personalization-script"
        strategy="beforeInteractive"
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `if (document.querySelector(".main")) {
            if (
              Tapcart.variables.device.id === "BE5C7469-A800-45C8-B0E9-1E92284B4277"
            ) {
              document.querySelector(".main").style.display = "block";
            } else {
              document.querySelector(".main").style.display = "none";
            }
          }`,
        }}
      />
    </>
  );
}

export default Libs;

import { Box, Paragraph } from 'theme-ui';

import { Link, Picture } from '@snippets';

import { useBreakpointValue } from '@hooks';

import { themed } from './Tile.theme';

export const Tile = themed(({ theme, item, settings, inputRef, ...props }) => {
  const { ratio, ratioDt, enableContained } = settings;
  const { media, content } = item;
  const { image, video, alt, imageHover, videoHover } = media;
  const { heading, headingMobile, description, descriptionMobile, alignment, alignmentMobile, cta, style } = content;
  const disableHoverMobile = useBreakpointValue([true, true, false]);

  return (
    <Box data-comp={Tile.displayName} {...props}>
      <Link
        href={cta?.url}
        newTab={cta?.newTab}
        sx={cta?.url ? theme.imageLink : theme.image}
      >
        <Picture
          alt={alt}
          images={[
            {
              src: image?.src,
              width: 768,
              ratio: parseFloat(ratio)
            },
            {
              src: image?.src,
              width: 700,
              ratio: parseFloat(ratioDt)
            },
          ]}
          hoverImages={[
            {
              src: imageHover?.src,
              width: 768,
              ratio: parseFloat(ratio)
            },
            {
              src: imageHover?.src,
              width: 700,
              ratio: parseFloat(ratioDt)
            },
          ]}
          video={video}
          videoHover={videoHover}
          videoRatio={parseFloat(ratio)}
          videoRatioDt={parseFloat(ratioDt)}
          disableHoverMobile={disableHoverMobile}
          width={image?.width}
          height={image?.height}
        />
        <Box sx={
            enableContained ?
              {
                ...theme.overlayContained,
                textAlign: alignment
              }:{
                ...theme.overlay,
                textAlign: alignment
              }
          }>
          {heading &&
            <Paragraph
              sx={theme.heading}
            >
              {heading}
            </Paragraph>
          }
          {description &&
            <Paragraph
              sx={theme.description}
            >
              {description}
            </Paragraph>
          }
          {cta?.text &&
            <Paragraph
              variant={`buttons.${style || 'primary'}`}
              sx={theme.button}
            >
              {cta?.text}
            </Paragraph>
          }
        </Box>
        <Box sx={
            enableContained ?
              {
                ...theme.overlayContainedMobile,
                textAlign: alignmentMobile
              }:{
                ...theme.overlayMobile,
                textAlign: alignmentMobile
              }
          }>
          {headingMobile &&
            <Paragraph
              sx={theme.heading}
            >
              {headingMobile}
            </Paragraph>
          }
          {descriptionMobile &&
            <Paragraph
              sx={theme.description}
            >
              {descriptionMobile}
            </Paragraph>
          }
          {cta?.text &&
            <Paragraph
              variant={`buttons.${style || 'primary'}`}
              sx={theme.button}
            >
              {cta?.text}
            </Paragraph>
          }
        </Box>
      </Link>
    </Box>
  );
});

Tile.displayName = 'Tile';

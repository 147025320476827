import { useCallback, useMemo } from 'react';
import { Flex, Box } from 'theme-ui';
import { useInView } from 'react-intersection-observer';
import {
  useLocalizedVariant,
  useProductByHandle,
  useSettings,
} from '@backpackjs/storefront';
import { useActions, useVariables } from '@tapcart/webbridge-react';

import { AddToCart, Link } from '@snippets';

import { useDataLayerActions, useGatedProduct } from '@hooks';

import store, { useRecoilValue } from '@store';

import { Image as ProductImage } from './Image';
import { Details } from './Details';
import { themed } from './ProductItem.theme';

export const ProductItem = themed(
  ({
    theme,
    product,
    isProductRow = false,
    hideAddToCart = false,
    onItemClick = () => null,
    index,
    inputRef,
    isSearchPage,
    searchTerm,
    ...props
  }) => {
    const { sendClickProductItemEvent } = useDataLayerActions();
    const gatedPage = useRecoilValue(store.gatedPage);
    const settings = useSettings();
    const { gatedPages } = { ...settings?.gatedPages } || {};

    const { enableFullProductAccess } = useGatedProduct({ product, gatedPage });
    const { isInitialized } = useVariables();
    const { openProduct, addToCart: addToTapCart } = useActions();

    const { ref, inView } = useInView({
      rootMargin: '400px',
      triggerOnce: true,
    });

    const { product: fullProduct } = useProductByHandle({
      handle: product.handle,
      fetchOnMount: inView,
    });

    const selectedVariant = useMemo(() => {
      return fullProduct?.variants?.[0];
    }, [fullProduct?.id]);

    const { localized } = useLocalizedVariant({ variant: selectedVariant });

    const hasMultipleVariants = useMemo(() => {
      if (!fullProduct) return false;
      let count = 0;
      let hasMultiple = false;
      fullProduct.variants?.find((variant) => {
        if (variant.product.handle === fullProduct.handle) {
          count += 1;
          if (count > 1) {
            hasMultiple = true;
            return true;
          }
        }
        return false;
      });
      return hasMultiple;
    }, [fullProduct?.id]);

    const hiddenProductHandles = useMemo(() => {
      return (
        !!gatedPages?.length &&
        gatedPages.reduce((carry, { enable, page }) => {
          if (enable) {
            const { pages } = { ...page };
            const _products = pages?.reduce((_carry, _page) => {
              if (_page?.enableFullProductAccess) {
                return _carry;
              }
              return [
                ..._carry,
                ...(_page?.products
                  ?.map(({ product }) => product?.handle)
                  ?.filter(Boolean) || []),
              ];
            }, []);
            return [...carry, ...(_products || [])];
          }
          return carry;
        }, [])
      );
    }, [gatedPages]);

    const intellisuggestTrackClick = useCallback(() => {
      if (document.images) {
        const apiUrl = `${window.location.protocol || 'http:'}//${
          process.env.NEXT_PUBLIC_SEARCHSPRING_SITE_ID
        }.a.searchspring.io/api/`;

        const imgTag = new Image();
        imgTag.src = `${apiUrl}track/track.json?d=${
          product.intellisuggestData
        }&s=${product.intellisuggestSignature}&u=${encodeURIComponent(
          `${process.env.SITE_URL}/products/${product.handle}`
        )}&r=${encodeURIComponent(
          document.referrer
        )}&bgfilter.ss_mobile_only=0`;
      }

      return true;
    }, [product.id]);

    const handleClick = useCallback(
      async (event) => {
        if (isInitialized && fullProduct?.legacyResourceId) {
          event.preventDefault();
          openProduct({
            productId: `${fullProduct.legacyResourceId}`,
          });
          return;
        }
        sendClickProductItemEvent({
          isSearchResult: isSearchPage,
          listIndex: index,
          localized,
          product: fullProduct,
          searchTerm,
          selectedVariant,
        });
        intellisuggestTrackClick();
      },
      [index, localized, fullProduct?.id, selectedVariant?.id, isInitialized]
    );

    return (
      <Flex
        data-comp={ProductItem.displayName}
        ref={ref}
        {...props}
        onClick={(event) => onItemClick(event, index)}
        sx={{
          ...theme.wrapper,
          ...props.sx,
        }}
      >
        <Box sx={{ width: '100%' }}>
          <ProductImage
            fullProduct={fullProduct}
            handleClick={handleClick}
            enableFullProductAccess={enableFullProductAccess}
          />

          <Details
            product={product}
            fullProduct={fullProduct}
            isProductRow={isProductRow}
            handleClick={handleClick}
            enableFullProductAccess={enableFullProductAccess}
          />
        </Box>

        {!hideAddToCart &&
          !hasMultipleVariants &&
          enableFullProductAccess &&
          hiddenProductHandles?.length &&
          hiddenProductHandles.indexOf(product?.handle) === -1 && (
            <AddToCart
              product={product}
              selectedVariant={selectedVariant}
              isProductItem
              style={theme.atc}
              addToTapCart={addToTapCart}
              isInitialized={isInitialized}
            />
          )}

        {hasMultipleVariants && enableFullProductAccess && (
          <Link
            data-comp="SeeDetails"
            href={`/products/${fullProduct?.handle}`}
            aria-label={`View details for ${fullProduct?.title}`}
            variant="buttons.primary"
            sx={theme.atc}
            onClick={handleClick}
          >
            See Details
          </Link>
        )}
      </Flex>
    );
  }
);

ProductItem.displayName = 'ProductItem';

import { useEffect, useState } from 'react';
import { Spinner, Flex } from 'theme-ui';

import { Link, Picture, Badge, Favorite } from '@snippets';
import { useBreakpointValue, useProductItemImages } from '@hooks';

import { themed } from './Image.theme';

export const Image = themed(
  ({ theme, fullProduct, handleClick, enableFullProductAccess = true }) => {
    const { primary, hover } = useProductItemImages({ product: fullProduct });

    const [disableHoverMobile, setDisableHoverMobile] = useState(false);
    const disableHover = useBreakpointValue([true, false]);

    useEffect(() => {
      setDisableHoverMobile(disableHover);
    }, [disableHover]);

    return (
      <Link
        data-comp={Image.displayName}
        href={
          enableFullProductAccess ? `/products/${fullProduct?.handle}` : null
        }
        aria-label={fullProduct?.title}
        sx={theme.image}
        onClick={handleClick}
      >
        <Picture
          alt={fullProduct?.title}
          images={[
            {
              src: primary?.originalSrc,
              width: 350,
              ratio: 1,
            },
          ]}
          hoverImages={[
            {
              src: hover?.originalSrc,
              width: 350,
              ratio: 1,
            },
          ]}
          disableHoverMobile={disableHoverMobile}
          imageBgColor="lightGray"
          hoverImageBgColor="lightGray"
          width={primary?.width}
          height={primary?.height}
        >
          {!primary?.originalSrc && (
            <Flex sx={theme.spinnerWrapper}>
              <Spinner sx={theme.spinner} />
            </Flex>
          )}
        </Picture>

        <Badge handle={fullProduct?.handle} product={fullProduct} />

        <Favorite selectedVariant={fullProduct?.variants?.[0]} />
      </Link>
    );
  }
);

Image.displayName = 'Image';

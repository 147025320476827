import { useState, useMemo } from 'react';
import { useCustomer } from '@backpackjs/storefront';
import { Heading, Paragraph } from 'theme-ui';
import { Section } from '@snippets';
import { themed } from './LoyaltylionStaticRewards.theme';
import { Schema } from './LoyaltylionStaticRewards.schema';

export const LoyaltylionStaticRewards = themed(({ theme, cms }) => {
  const moduleID = `${Schema().key}-${cms.id || cms.cmsId || cms.tinaId}`;
  const customer = useCustomer();
  const {
    state,
    userTag,
    heading,
    headingColor,
    subHeading,
    rewardsList,
    section,
  } = cms;
  const textAlign = [cms?.alignment, cms?.alignmentDt];

  const userTags = useMemo(() => {
    if (!userTag || userTag === '') return [];
    return userTag?.split(',')?.map((tag) => tag.trim());
  }, [userTag]);

  const enable = useMemo(() => {
    return (
      state === 'enable' ||
      (state === 'guest' && !customer?.id) ||
      (state === 'user' && !!customer?.id) ||
      (state === 'userByTag' &&
        customer?.id &&
        userTags?.length &&
        userTags?.some((tag) => customer?.tags?.includes(tag))) ||
      (state === 'userWithoutTag' &&
        customer?.id &&
        userTags?.length &&
        userTags?.every((tag) => !customer?.tags?.includes(tag)))
    );
  }, [state, customer?.id, customer?.tags, userTags]);

  if (!enable) return null;

  return (
    <Section
      id={moduleID}
      data-comp={LoyaltylionStaticRewards.displayName}
      section={section}
    >
      <div className="py-10">
        {heading && (
          <Heading
            sx={{
              ...theme.heading,
              textAlign,
              color: headingColor || 'text',
            }}
          >
            {heading}
          </Heading>
        )}
        {subHeading && (
          <Paragraph
            sx={{
              ...theme.paragraph,
              textAlign,
              color: headingColor || 'text',
            }}
            dangerouslySetInnerHTML={{ __html: subHeading }}
          />
        )}
        <div id="loyaltylion" className="m-auto block">
          <div className="lion-rewards-list">
            {rewardsList?.map((reward, index) => {
              return (
                <div
                  key={index}
                  className={`lion-reward-item lion-reward-item--product-discount-voucher lion-reward-item--customer lion-reward-item${
                    index + 1
                  }`}
                >
                  <div className="lion-reward-item__content">
                    <div className="lion-reward-item__icon lion-icon lion-icon__reward lion-icon__reward--product-discount-voucher before:!bg-none relative z-0">
                      <div className="lion-reward-item__icon__image absolute w-full h-full p-[1.5rem] flex items-center">
                        <img
                          src={reward?.icon?.src}
                          alt={reward?.icon?.alt}
                          className="w-full h-auto"
                        />
                      </div>
                    </div>
                    <div className="lion-reward-item__title">
                      {reward?.title}
                    </div>
                    <div className="lion-reward-item__meta">
                      {reward?.subTitle}
                    </div>
                    <div className="lion-reward-item__actions">
                      <div className="lion-action-button lion-action-button--tile lion-action-button--disabled">
                        <span className="lion-reward-item__redeem-button-text lion-loyalty-page-reward-item__redeem-button-text">
                          {reward?.buttonText}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </Section>
  );
});

LoyaltylionStaticRewards.displayName = 'Loyaltylion Static Rewards List';
LoyaltylionStaticRewards.Schema = Schema;

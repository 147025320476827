import Head from 'next/head';
import { Box } from 'theme-ui';

export function PictureSource({
  breakpoint,
  breakpointPrev,
  isLast,
  width,
  src,
  preload,
}) {
  const media = isLast
    ? `(min-width: ${breakpointPrev})`
    : `(max-width: ${breakpoint})`;

  const breakpointPx = Math.round(parseFloat(breakpoint.split('em')[0]) * 16);
  return (
    <>
      {preload && (
        <Head>
          <link rel="preload" href={src} as="image" media={media} />
        </Head>
      )}
      <Box
        as="source"
        data-comp={PictureSource.displayName}
        data-render-until={`${breakpointPx}px/${breakpoint}`}
        data-crop-width={`${width}px`}
        media={media}
        srcSet={src}
      />
    </>
  );
}

PictureSource.displayName = 'PictureSource';

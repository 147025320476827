import { useCallback, useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { nanoid } from 'nanoid';

import { mapProductItemProduct, mapProductItemVariant } from './utils';

export function useDataLayerSearch({
  baseEcommerce,
  DEBUG,
  userDataEvent,
  userDataEventTriggered,
  userProperties,
}) {
  const asPathRef = useRef(null);
  const router = useRouter();
  const asPath = router.asPath.split('?')[0];

  const [searchPageResults, setSearchPageResults] = useState(null);
  const [searchResults, setSearchResults] = useState(null);
  const [clickedSearchResultsItem, setClickedSearchResultsItem] =
    useState(null);

  const viewSearchResultsEvent = useCallback(
    ({ ecommerce, results, userProperties: _userProperties }) => {
      if (!results?.length) return;
      const event = {
        event: 'dl_view_search_results',
        event_id: nanoid(),
        event_time: new Date().toISOString(),
        user_properties: _userProperties,
        ecommerce: {
          ...ecommerce,
          actionField: {
            list: 'search_results',
            search_term: 'redacted',
          },
          products: results.slice(0, 12).map(mapProductItemProduct()),
        },
      };

      window.dispatchEvent(
        new CustomEvent('dl_view_search_results', {
          detail: event,
        })
      );
      if (DEBUG) console.log(`DataLayer:dispatch:${event.event}`, event);
    },
    []
  );

  const clickSearchResultsItemEvent = useCallback(
    ({ ecommerce, userProperties: _userProperties, variant }) => {
      if (!variant) return;
      const event = {
        event: 'dl_select_item',
        event_id: nanoid(),
        event_time: new Date().toISOString(),
        user_properties: _userProperties,
        ecommerce: {
          ...ecommerce,
          click: {
            actionField: {
              list: 'search_results',
              action: 'click',
              search_term: variant.searchTerm,
            },
            products: [variant].map(mapProductItemVariant()),
          },
        },
      };

      window.dispatchEvent(
        new CustomEvent('dl_select_item', {
          detail: event,
        })
      );
      if (DEBUG) console.log(`DataLayer:dispatch:${event.event}`, event);
    },
    []
  );

  // Subscribe to PubSub topics for 'view_search_results' and 'select_item' events
  useEffect(() => {
    const viewSearchPageResults = PubSub.subscribe(
      'VIEW_SEARCH_PAGE_RESULTS',
      async (event, results) => {
        setSearchPageResults(results);
      }
    );
    const viewSearchResults = PubSub.subscribe(
      'VIEW_SEARCH_RESULTS',
      async (event, results) => {
        setSearchResults(results);
      }
    );
    const clickSearchItem = PubSub.subscribe(
      'CLICK_SEARCH_ITEM',
      async (event, variant) => {
        setClickedSearchResultsItem(variant);
      }
    );
    return () => {
      if (viewSearchPageResults) {
        PubSub.unsubscribe(viewSearchPageResults);
      }
      if (viewSearchResults) {
        PubSub.unsubscribe(viewSearchResults);
      }
      if (clickSearchItem) {
        PubSub.unsubscribe(clickSearchItem);
      }
    };
  }, []);

  // Trigger 'dl_user_data' and 'dl_view_search_results' events after
  // new sidebar search results and base data is ready
  useEffect(() => {
    if (
      !asPath.startsWith('/pages/search') ||
      !baseEcommerce ||
      !searchPageResults?.length ||
      !userProperties ||
      asPath === asPathRef.current
    )
      return undefined;
    userDataEvent({ ecommerce: baseEcommerce, userProperties });
    viewSearchResultsEvent({
      ecommerce: baseEcommerce,
      results: searchPageResults,
      userProperties,
    });
    asPathRef.current = asPath;
    return () => {
      asPathRef.current = null;
    };
  }, [
    asPath,
    !!baseEcommerce,
    searchPageResults?.map((p) => p?.handle).join(''),
    !!userProperties,
  ]);

  // Trigger 'dl_user_data' and 'dl_view_search_results' events after
  // new search page results and base data is ready
  useEffect(() => {
    if (!baseEcommerce || !searchResults || !userDataEventTriggered) return;
    viewSearchResultsEvent({
      ecommerce: baseEcommerce,
      results: searchResults,
      userProperties,
    });
  }, [!!baseEcommerce, searchResults, userDataEventTriggered]);

  // Trigger 'dl_select_item' after clicked search item and user event
  useEffect(() => {
    if (!clickedSearchResultsItem || !baseEcommerce || !userDataEventTriggered)
      return;
    clickSearchResultsItemEvent({
      ecommerce: baseEcommerce,
      userProperties,
      variant: clickedSearchResultsItem,
    });
  }, [clickedSearchResultsItem, !!baseEcommerce, userDataEventTriggered]);
}

import { useRef } from 'react';
import { Image } from 'theme-ui';

export function PictureImage({
  aboveTheFold,
  alt,
  imageSx,
  initialSrc,
  preload,
  width,
  height,
  isHover,
  onError = () => {},
  onLoad = () => {},
}) {
  const imageRef = useRef();
  const imgLoading = !isHover && (preload || aboveTheFold) ? 'eager' : 'lazy';

  return (
    <Image
      data-comp={PictureImage.displayName}
      ref={imageRef}
      alt={alt}
      src={initialSrc}
      width={width}
      height={height}
      sx={{
        // over-writable..
        height: '100%',
        position: 'absolute',
        width: '100%',
        outline: 'none',
        objectFit: 'cover',
        border: 0,
        p: 0,
        m: 0,
        bottom: 0,
        left: 0,
        right: 0,
        top: 0,
        ...imageSx,
      }}
      fetchpriority={preload ? 'high' : undefined}
      loading={imgLoading}
      onLoad={() => onLoad(imageRef.current)}
      onError={(event) => {
        console.error('Error loading image with src', event.target.currentSrc);
        onError(event);
      }}
    />
  );
}

PictureImage.displayName = 'PictureImage';

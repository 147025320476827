import {
  useState,
  useEffect,
  useCallback,
  Children,
  cloneElement,
  isValidElement,
} from 'react';
import { Box } from 'theme-ui';
import PropTypes from 'prop-types';
import { useInView } from 'react-intersection-observer';

import { breakpoints } from 'src/theme/theme.breakpoints';

import { usePicture } from './usePicture';
import { PictureSource } from './PictureSource';
import { PictureImage } from './PictureImage';
import { themed } from './Picture.theme';

export const Picture = themed(
  ({
    // inView settings
    aboveTheFold = false,
    preload = false,
    alt = 'Image',
    children,
    disableHoverMobile = false,
    foreignHover = false, // set it hovered from an external source/element
    hoverImages = [],
    id,
    inputRef,
    images = [{ src: '/svgs/default-image.svg', width: 600, ratio: 1 }],
    imageSx = {},
    rootMargin = '100px',
    theme,
    threshold = 0.01,
    triggerOnce = true,
    variant = null,
    imageBgColor = null,
    hoverImageBgColor = null,
    video = '',
    videoHover = '',
    videoRatio,
    videoRatioDt,
    mobileRatio,
    desktopRatio,
    width,
    height,
    // events
    onError = () => {},
    onInView = () => {},
    onLoad = () => {},
    onMouseEnter = () => {},
    onMouseLeave = () => {},
    ...props
  }) => {
    const [picture, hoverPicture] = usePicture({ images, hoverImages });
    const [hovered, setHovered] = useState(foreignHover);
    const [windowWidth, setWindowWidth] = useState();

    const disableHoverPicture =
      hoverPicture?.sources?.[0] === '/svgs/default-image.svg';

    const hasHoverPicture =
      hoverPicture?.sources?.length > 0 &&
      !disableHoverMobile &&
      !disableHoverPicture;

    const hasHoverVideo = videoHover && !disableHoverMobile;

    const ratioCalculatedWidth =
      windowWidth >= 1024 ? desktopRatio * 1000 : mobileRatio * 1000;

    useEffect(() => {
      setHovered(foreignHover);
    }, [foreignHover]);

    const [inViewRef, inView] = useInView({
      rootMargin,
      triggerOnce,
      threshold,
    });

    const setRefs = useCallback(
      (node) => {
        if (inputRef) inputRef.current = node;
        inViewRef(node);
      },
      [inViewRef]
    );

    const updateDimensions = () => {
      setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
      updateDimensions();
      window.addEventListener('resize', updateDimensions);
      return () => window.removeEventListener('resize', updateDimensions);
    }, []);

    useEffect(() => onInView(inView), [inView]);

    return (
      <Box
        data-comp={Picture.displayName}
        id={id}
        {...props}
        ref={setRefs}
        sx={{
          ...theme.wrapper,
          // overwriteable
          ...props.sx,
          variant,
          ':before': {
            content: '""',
            paddingBottom: picture.paddingBottom,
            width: 0,
          },
        }}
        onMouseEnter={() => {
          onMouseEnter();
          setHovered(true);
        }}
        onMouseLeave={() => {
          onMouseLeave();
          setHovered(false);
        }}
      >
        {hasHoverVideo ? (
          <Box
            as="video"
            muted
            playsInline
            loop
            autoPlay
            sx={{
              ...theme.hoverVideo,
              opacity: hovered ? 1 : 0,
              transition: 'opacity .3s ease-in-out',
              bg: hoverImageBgColor,
              aspectRatio: videoRatioDt,
              '@media only screen and (max-width: 767px)': {
                aspectRatio: videoRatio,
              },
            }}
          >
            <source key={videoHover} src={videoHover} type="video/mp4" />
          </Box>
        ) : null}
        {/* hover <picture> */}
        {!videoHover && hasHoverPicture ? (
          <Box
            data-comp={`${Picture.displayName}PictureHover`}
            as="picture"
            sx={{
              ...theme.hoverImage,
              opacity: hovered ? 1 : 0,
              transition: 'opacity .3s ease-in-out',
              bg: hoverImageBgColor,
            }}
          >
            {/* loop through <source(s)> */}
            {hoverPicture.sources.map((source, index, sources) => {
              const hoverImage = hoverImages?.[index] || null;
              const breakpoint = breakpoints?.[index] || null;
              const breakpointPrev = breakpoints?.[index - 1] || null;
              const isLast = index === sources.length - 1;
              if (!breakpoint || !hoverImage) return null;

              return (
                <PictureSource
                  key={`Hover ${
                    index + (hoverImage?.width || hoverImage?.ratio || '')
                  }`}
                  preload={preload}
                  breakpoint={breakpoint}
                  breakpointPrev={breakpointPrev}
                  width={hoverImage.width}
                  src={source}
                  isLast={isLast}
                />
              );
            })}

            {/* <img /> */}
            <PictureImage
              alt={alt}
              initialSrc={hoverPicture.sources.find(Boolean)} // default to first breakpoint (mobile)
              imageSx={imageSx}
              isHover
              aboveTheFold
              preload={preload}
              inView={inView}
              width={width}
              height={height}
            />
          </Box>
        ) : null}

        {video ? (
          <Box
            as="video"
            muted
            playsInline
            loop
            autoPlay
            sx={{
              ...theme.video,
              opacity: hovered && (hasHoverPicture || hasHoverVideo) ? 0 : 1,
              transition: 'opacity .2s ease-in-out',
              bg: imageBgColor,
              aspectRatio: videoRatioDt,
              '@media only screen and (max-width: 767px)': {
                aspectRatio: videoRatio,
              },
            }}
          >
            <source key={video} src={video} type="video/mp4" />
          </Box>
        ) : (
          <Box
            data-comp={`${Picture.displayName}Picture`}
            as="picture"
            sx={{
              ...theme.image,
              opacity: hovered && (hasHoverPicture || hasHoverVideo) ? 0 : 1,
              transition: 'opacity .2s ease-in-out',
              bg: imageBgColor,
            }}
          >
            {/* loop through <source(s)> */}
            {picture.sources.map((source, index, sources) => {
              const image = images?.[index] || null;
              const breakpoint = breakpoints?.[index] || null;
              const breakpointPrev = breakpoints?.[index - 1] || null;
              const isLast = index === sources.length - 1;
              if (!breakpoint || !image) return null;
              return (
                <PictureSource
                  key={index + (image?.width || image?.ratio || '')}
                  breakpoint={breakpoint}
                  breakpointPrev={breakpointPrev}
                  width={image.width}
                  src={source}
                  isLast={isLast}
                  preload={preload}
                />
              );
            })}

            {/* <img /> */}
            <PictureImage
              alt={alt}
              initialSrc={picture.sources.find(Boolean)} // default to first breakpoint (mobile)
              imageSx={imageSx}
              onError={onError}
              onLoad={onLoad}
              aboveTheFold={aboveTheFold}
              preload={preload}
              inView={inView}
              width={desktopRatio && mobileRatio ? ratioCalculatedWidth : width}
              height={desktopRatio && mobileRatio ? 1000 : height}
            />
          </Box>
        )}
        {/* Render inner elements */}
        {Children.map(children, (child) => {
          if (!isValidElement(child)) return null;
          return cloneElement(child, {
            // Don't change 'relative'
            style: { position: 'relative' },
          });
        })}
      </Box>
    );
  }
);

Picture.displayName = 'Picture';
Picture.propTypes = {
  aboveTheFold: PropTypes.bool,
  preload: PropTypes.bool,
  alt: PropTypes.string,
  children: PropTypes.node,
  foreignHover: PropTypes.bool,
  hoverImages: PropTypes.arrayOf(
    PropTypes.exact({
      src: PropTypes.string,
      width: PropTypes.number,
      ratio: PropTypes.number,
    })
  ),
  id: PropTypes.string,
  images: PropTypes.arrayOf(PropTypes.object),
  imageSx: PropTypes.object,
  rootMargin: PropTypes.string,
  sx: PropTypes.object,
  threshold: PropTypes.number,
  triggerOnce: PropTypes.bool,
  variant: PropTypes.string,
  onError: PropTypes.func,
  onInView: PropTypes.func,
  onLoad: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
};

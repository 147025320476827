import Script from 'next/script';

export function FueledUsableScript() {
  return (
    <Script
      strategy="afterInteractive"
      src="https://a40.usablenet.com/pt/c/brumate/cookiestorestart"
    />
  );
}

import {
  position,
  sectionWithHeight,
  color,
  align,
  button,
  timezones,
} from '@settings/common';
import { DateTime } from 'luxon';

const video = () => ({
  label: 'Video Settings',
  name: 'video',
  description: 'Video, controls, autoplay, loop',
  component: 'group',
  fields: [
    {
      name: 'srcDt',
      component: 'text',
      label: 'Video URL (desktop)',
      description:
        'File size should remain below 15MB for mobile data consideration',
    },
    {
      name: 'src',
      component: 'text',
      label: 'Video URL (mobile)',
      description:
        'Direct links to Mp4/Webm videos recommended. Note: File size should remain around 15MB MAX for mobile data consideration',
    },
    {
      name: 'posterDt',
      component: 'image',
      label: 'Poster image (Desktop)',
    },
    {
      name: 'poster',
      component: 'image',
      label: 'Poster image (Mobile)',
      description:
        'Poster image for video. Usually the first frame of the video',
    },
    {
      name: 'autoplay',
      label: 'Autoplay',
      component: 'toggle',
      description:
        'Autoplay video on load (Embed only). The video will be muted',
      toggleLabels: {
        true: 'On',
        false: 'Off',
      },
    },
    {
      name: 'loop',
      label: 'Loop video',
      component: 'toggle',
      description: 'Loop video when it ends (Embed only)',
      toggleLabels: {
        true: 'On',
        false: 'Off',
      },
    },
    {
      name: 'controls',
      label: 'Show controls',
      component: 'toggle',
      description: 'Show video controls (Embed only)',
      toggleLabels: {
        true: 'On',
        false: 'Off',
      },
    },
    {
      name: 'muted',
      label: 'Mute Video',
      component: 'toggle',
      description: 'Mute video on load (Embed only)',
      toggleLabels: {
        true: 'Muted',
        false: 'Unmuted',
      },
      defaultValue: true,
    },
  ],
  defaultValue: {
    autoplay: true,
    loop: true,
    controls: false,
    muted: true,
  },
});

const image = ({ position }) => ({
  label: 'Image Settings',
  name: 'image',
  description: 'Image, image position',
  component: 'group',
  fields: [
    {
      name: 'alt',
      component: 'text',
      label: 'Image Alt',
      description:
        'Provide a brief description of the image for accessibility compliance',
    },
    {
      name: 'imageDt',
      label: 'Image (desktop)',
      component: 'image',
    },
    {
      name: 'positionDt',
      component: 'select',
      label: 'Image position (desktop)',
      options: position.object,
    },
    {
      name: 'image',
      label: 'Image (mobile)',
      component: 'image',
    },
    {
      name: 'position',
      component: 'select',
      label: 'Image position (mobile)',
      options: position.object,
    },
  ],
  defaultValue: {
    positionDt: 'center center',
    position: 'center center',
  },
});

const text = ({ color }) => ({
  label: 'Text Settings',
  name: 'text',
  description: 'Heading, superheading, subheading, color',
  component: 'group',
  fields: [
    {
      name: 'heading',
      component: 'markdown',
      label: 'Heading',
      description:
        'Ensure heading is "H1" by starting with "# " (Pound/Hash and a space preceeding your text). E.g.: "# Shop All". Text below will become large and bold to indicate "H1".',
    },
    {
      name: 'headingOption',
      label: 'Font Family Heading Option',
      component: 'radio-group',
      direction: 'horizontal',
      variant: 'radio',
      options: [
        { label: 'Reckless Neue', value: 'text.h1' },
        { label: 'Fellix', value: 'text.h1.2' },
      ],
      defaultValue: 'text.h1',
    },
    {
      name: 'superheading',
      component: 'text',
      label: 'Superheading',
    },
    {
      name: 'subheading',
      component: 'markdown',
      label: 'Subheading',
      description:
        'Tip: You can add in-line links by highlighting the section of text and clicking the link button in the menu options.',
    },
    {
      label: 'Hide Heading For SEO',
      description:
        'When hero media contains the messenging, hide the heading text for SEO',
      name: 'hiddenHeadingForSeo',
      component: 'toggle',
      toggleLabels: {
        true: 'On',
        false: 'Off',
      },
    },
    {
      component: 'select',
      name: 'colorDt',
      label: 'Text color (tablet & desktop)',
      options: [{ label: 'Default', value: color.text }, ...color.options],
    },
    {
      component: 'select',
      name: 'color',
      label: 'Text color (mobile)',
      options: [{ label: 'Default', value: color.text }, ...color.options],
    },
  ],
  defaultValue: {
    heading: 'RED VELVET COLLECTION',
    subheading: 'Serving up Luxe, one drink at a time.',
    hiddenHeadingForSeo: false,
  },
});

const buttons = ({ button }) => ({
  label: 'Button Settings',
  name: 'button',
  description: 'Buttons, clickable hero',
  component: 'group',
  fields: [
    {
      label: 'Clickable hero',
      description:
        'Allows entire hero to be clickable, using the first button link as url',
      name: 'clickableHero',
      component: 'toggle',
      toggleLabels: {
        true: 'On',
        false: 'Off',
      },
      defaultValue: true,
    },
    {
      label: 'Hide buttons',
      name: 'hideButtons',
      component: 'toggle',
      toggleLabels: {
        true: 'On',
        false: 'Off',
      },
      defaultValue: false,
    },
    {
      name: 'buttonWidth',
      label: 'Button Width (mobile)',
      component: 'radio-group',
      direction: 'horizontal',
      variant: 'radio',
      options: [
        { label: 'Full Width', value: '100%' },
        { label: 'Fit Content', value: '140px' },
      ],
      defaultValue: '140px',
    },
    {
      name: 'buttons',
      label: 'Buttons',
      component: 'group-list',
      description: 'Max of 2 buttons',
      itemProps: {
        label: '{{item.cta.text}}',
      },
      validate: {
        maxItems: 2,
      },
      defaultItem: {
        cta: {
          text: 'Shop Now',
          url: '/collections/all',
        },
        style: 'primary',
      },
      fields: [
        {
          name: 'cta',
          component: 'link',
          label: 'Button',
        },
        {
          name: 'style',
          label: 'Button style',
          component: 'select',
          options: button.options,
        },
      ],
      defaultValue: [
        {
          cta: {
            text: 'Shop Now',
            url: '/collections/all',
          },
          style: 'primary',
        },
      ],
    },
  ],
});

const content = ({ position }) => ({
  label: 'Content Settings',
  name: 'content',
  description: 'Content alignment, content position',
  component: 'group',
  fields: [
    {
      label: 'Enable Content',
      name: 'enableContent',
      description: 'Show Content',
      component: 'toggle',
      toggleLabels: {
        true: 'On',
        false: 'Off',
      },
    },
    {
      label: 'Enable Video/Image',
      name: 'enableMedia',
      description: 'Show Video/Image',
      component: 'toggle',
      toggleLabels: {
        true: 'On',
        false: 'Off',
      },
    },
    {
      name: 'alignmentDt',
      label: 'Content alignment (desktop)',
      component: 'radio-group',
      direction: 'horizontal',
      variant: 'radio',
      options: align.options,
    },
    {
      name: 'positionDt',
      component: 'select',
      label: 'Content position (desktop)',
      options: position.flex,
    },
    {
      name: 'alignment',
      label: 'Content alignment (mobile)',
      component: 'radio-group',
      direction: 'horizontal',
      variant: 'radio',
      options: align.options,
    },
    {
      name: 'position',
      component: 'select',
      label: 'Content position (mobile)',
      options: position.flex,
    },
    {
      name: 'maxWidthDt',
      component: 'number',
      label: 'Max content pixel width (desktop)',
    },
    {
      name: 'maxWidth',
      component: 'number',
      label: 'Max content pixel width (mobile)',
    },
  ],
  defaultValue: {
    alignmentDt: 'left',
    positionDt: 'left center',
    alignment: 'left',
    position: 'left center',
    maxWidthDt: 600,
    maxWidth: 343,
  },
});

const slider = ({ color }) => ({
  label: 'Slider Settings',
  name: 'slider',
  description:
    'Autoplay, delay, transition effect, bullet color, content position',
  component: 'group',
  fields: [
    {
      label: 'Enable autoplay',
      name: 'autoplay',
      description: 'Refresh page to observe change',
      component: 'toggle',
      toggleLabels: {
        true: 'On',
        false: 'Off',
      },
    },
    {
      label: 'Enable pagination',
      name: 'pagination',
      description: 'Show navigation controls',
      component: 'toggle',
      toggleLabels: {
        true: 'On',
        false: 'Off',
      },
    },
    {
      name: 'delay',
      component: 'number',
      description: 'Refresh page to observe change',
      label: 'Delay between transitions (ms)',
    },
    {
      component: 'radio-group',
      direction: 'horizontal',
      variant: 'radio',
      name: 'effect',
      label: 'Effect between transitions',
      description: 'Refresh page to observe change',
      options: [
        { label: 'Slide', value: 'slide' },
        { label: 'Fade', value: 'fade' },
      ],
    },
    {
      component: 'select',
      name: 'activeBulletColor',
      label: 'Active bullet color',
      options: color.options,
    },
    {
      component: 'radio-group',
      direction: 'horizontal',
      variant: 'radio',
      name: 'contentPosition',
      label: 'Content position (mobile)',
      options: [
        { label: 'Contained', value: 'absolute' },
        { label: 'Below', value: 'relative' },
      ],
    },
    {
      component: 'radio-group',
      direction: 'horizontal',
      variant: 'radio',
      name: 'contentPositionDt',
      label: 'Content position (desktop)',
      options: [
        { label: 'Contained', value: 'absolute' },
        { label: 'Below', value: 'relative' },
      ],
    },
  ],
  defaultValue: {
    autoplay: true,
    pagination: true,
    delay: 8000,
    effect: 'fade',
    activeBulletColor: color.value.text,
    contentPosition: 'relative',
    contentPositionDt: 'relative',
  },
});

const defaultSlide1 = () => ({
  image: {
    alt: 'Woman in red holding cup.',
    image: {
      src: 'https://cdn.shopify.com/s/files/1/1114/2308/files/MobileHero.png',
    },
    position: 'center center',
    imageDt: {
      src: 'https://cdn.shopify.com/s/files/1/1114/2308/files/HeroImage.png',
    },
    positionDt: 'center center',
  },
  text: {
    heading: '# Red velvet collection',
    subheading: 'Serving up Luxe, one drink at a time.',
  },
  button: {
    buttons: [
      {
        cta: {
          text: 'Shop Now',
          url: '/collections/all',
        },
        style: 'primary',
      },
    ],
  },
  content: {
    alignment: 'left',
    position: 'left center',
    alignmentDt: 'left',
    positionDt: 'left center',
    maxWidth: 340,
    maxWidthDt: 600,
  },
});

const defaultSlide2 = () => ({
  video: {
    srcDt: 'https://cdn.shopify.com/s/files/1/1114/2308/files/Brumate.mp4',
    src: 'https://cdn.shopify.com/s/files/1/1114/2308/files/Brumate.mp4',
    posterDt: {
      src: 'https://cdn.shopify.com/s/files/1/1114/2308/files/default.png',
    },
    poster: {
      src: 'https://cdn.shopify.com/s/files/1/1114/2308/files/default.png',
    },
    autoplay: true,
    loop: true,
    controls: false,
    muted: true,
  },
  text: {
    heading: '# Introducing Brütank',
    subheading: 'Worlds first 55 quart, all-terrain cooler.',
  },
  button: {
    buttons: [
      {
        cta: {
          text: 'Shop Now',
          url: '/collections/brutank',
        },
        style: 'primary',
      },
    ],
  },
  content: {
    alignmentDt: 'left',
    positionDt: 'left center',
    alignment: 'left',
    position: 'left center',
    maxWidth: 340,
    maxWidthDt: 600,
  },
});

const section = ({ sectionWithHeight }) => ({
  ...sectionWithHeight,
  fields: [
    {
      label: 'Above the fold',
      name: 'aboveTheFold',
      component: 'toggle',
      toggleLabels: {
        true: 'Yes',
        false: 'No',
      },
      defaultValue: false,
    },
    ...sectionWithHeight.fields,
  ],
  defaultValue: {
    ...sectionWithHeight.desktop,
    desktop: {
      container: 'padded',
      mt: 'none',
      py: 'none',
    },
    ...sectionWithHeight.mobile,
    mobile: {
      container: 'padded',
      mt: 'none',
      py: 'none',
      minHeight: 375,
      maxHeight: 375,
    },
  },
});

const countDown = ({ color }) => ({
  label: 'Countdown Timer',
  name: 'countdown',
  component: 'group',
  fields: [
    {
      label: 'Title',
      name: 'title',
      component: 'text',
      description: ``,
      defaultValue: 'Countdown Timer Copy',
    },
    {
      name: 'titleFont',
      label: 'Font Family Option',
      component: 'radio-group',
      direction: 'horizontal',
      variant: 'radio',
      options: [
        { label: 'Reckless Neue', value: 'text.h1' },
        { label: 'Fellix', value: 'text.h1.2' },
      ],
      defaultValue: 'text.h1',
    },
    {
      label: 'Title Font Size',
      name: 'titleFontSize',
      component: 'select',
      options: [
        { label: 'H1', value: 'text.h1' },
        { label: 'H1.2', value: 'text.h1.2' },
        { label: 'H2', value: 'text.h2' },
        { label: 'H3', value: 'text.h3' },
        { label: 'H4', value: 'text.h4' },
        { label: 'H5', value: 'text.h5' },
        { label: 'H6', value: 'text.h6' },
        { label: 'SM', value: 'text.sm' },
        { label: 'Base', value: 'text.base' },
        { label: 'Large', value: 'text.lg' },
      ],
      defaultValue: 'text.h1',
    },
    {
      label: 'Background color',
      name: 'background',
      component: 'select',
      options: color.options.concat({ label: 'None', value: 'transparent' }),
      defaultValue: color.value.mediumGray,
    },
    {
      label: 'Text Color',
      name: 'clockColor',
      component: 'select',
      options: color.options,
      defaultValue: color.value.text,
    },
    {
      label: 'Clock Font Size',
      name: 'clockFontSize',
      component: 'select',
      options: [
        { label: 'H1', value: 'text.h1' },
        { label: 'H1.2', value: 'text.h1.2' },
        { label: 'H2', value: 'text.h2' },
        { label: 'H3', value: 'text.h3' },
        { label: 'H4', value: 'text.h4' },
        { label: 'H5', value: 'text.h5' },
        { label: 'H6', value: 'text.h6' },
        { label: 'SM', value: 'text.sm' },
        { label: 'Base', value: 'text.base' },
        { label: 'Large', value: 'text.lg' },
      ],
      defaultValue: 'text.h2',
    },
    {
      label: 'Target Time',
      name: 'targetTime',
      component: 'text',
      description: `Date format: ${DateTime.now().toFormat('MM-dd-yyyy tt')}`,
      defaultValue: DateTime.now().toFormat('MM-dd-yyyy tt'),
    },
    {
      label: 'Target TimeZone',
      name: 'timezone',
      component: 'select',
      options: timezones.options,
      defaultValue: 'America/Los_Angeles',
    },
  ],
});

export const Schema = () => {
  return {
    label: 'Hero Slider',
    key: 'hero-slider',
    fields: [
      {
        name: 'slides',
        label: 'Slides',
        component: 'group-list',
        itemProps: {
          label: '{{item.text.heading}}',
        },
        defaultItem: () => defaultSlide1,
        fields: [
          countDown({ color }),
          video(),
          image({ position }),
          text({ color }),
          buttons({ button }),
          content({ position }),
        ],
        defaultValue: [defaultSlide1(), defaultSlide2()],
      },
      slider({ color }),
      section({ sectionWithHeight }),
    ],
  };
};

import { useEffect, useMemo, useRef, useState } from 'react';
import { useScriptLoad } from './useScriptLoad';

export const CONSENT_API =
  'https://cdn.shopify.com/shopifycloud/consent-tracking-api/v0.1/consent-tracking-api.js';
export const CONSENT_API_WITH_BANNER =
  'https://cdn.shopify.com/shopifycloud/privacy-banner/storefront-banner.js';

function useApisLoaded({ withPrivacyBanner, onLoaded }) {
  const observing = useRef({ customerPrivacy: false, privacyBanner: false });

  const [apisLoaded, setApisLoaded] = useState(
    withPrivacyBanner ? [false, false] : [false]
  );

  const loaded = apisLoaded.every(Boolean);

  const setLoaded = {
    customerPrivacy: () => {
      if (withPrivacyBanner) {
        setApisLoaded((prev) => [true, prev[1]]);
      } else {
        setApisLoaded(() => [true]);
      }
    },
    privacyBanner: () => {
      if (!withPrivacyBanner) {
        return;
      }
      setApisLoaded((prev) => [prev[0], true]);
    },
  };

  useEffect(() => {
    if (loaded && onLoaded) {
      onLoaded();
    }
  }, [loaded, onLoaded]);

  return { observing, setLoaded };
}

function parseStoreDomain(checkoutDomain) {
  if (typeof window === 'undefined') return;

  const { host } = window.document.location;
  const checkoutDomainParts = checkoutDomain.split('.').reverse();
  const currentDomainParts = host.split('.').reverse();
  const sameDomainParts = [];
  checkoutDomainParts.forEach((part, index) => {
    if (part === currentDomainParts[index]) {
      sameDomainParts.push(part);
    }
  });

  return sameDomainParts.reverse().join('.'); // eslint-disable-line
}

function overridePrivacyBannerMethods({ privacyBanner, config }) {
  const originalLoadBanner = privacyBanner.loadBanner;
  const originalShowPreferences = privacyBanner.showPreferences;

  function loadBanner(userConfig) {
    if (typeof userConfig === 'object') {
      originalLoadBanner({ ...config, ...userConfig });
      return;
    }
    originalLoadBanner(config);
  }

  function showPreferences(userConfig) {
    if (typeof userConfig === 'object') {
      originalShowPreferences({ ...config, ...userConfig });
      return;
    }
    originalShowPreferences(config);
  }
  return { loadBanner, showPreferences };
}

function overrideCustomerPrivacySetTrackingConsent({
  customerPrivacy,
  config,
}) {
  const original = customerPrivacy.setTrackingConsent;
  const { locale, country, ...rest } = config;

  function updatedSetTrackingConsent(consent, callback) {
    original(
      {
        ...rest,
        headlessStorefront: true,
        ...consent,
      },
      callback
    );
  }
  return updatedSetTrackingConsent;
}

let hasEmitted = false;
function emitCustomerPrivacyApiLoaded() {
  if (hasEmitted) return;
  hasEmitted = true;
  const event = new CustomEvent('shopifyCustomerPrivacyApiLoaded');
  document.dispatchEvent(event);
}

export function getCustomerPrivacy() {
  try {
    return window.Shopify && window.Shopify.customerPrivacy
      ? window.Shopify?.customerPrivacy
      : null;
  } catch (e) {
    return null;
  }
}

export function getPrivacyBanner() {
  try {
    return window && window?.privacyBanner ? window.privacyBanner : null;
  } catch (e) {
    return null;
  }
}

export function useCustomerPrivacy(props) {
  const {
    withPrivacyBanner = false,
    onVisitorConsentCollected,
    onReady,
    ...consentConfig
  } = props;

  const { status } = useScriptLoad(
    {
      src: withPrivacyBanner ? CONSENT_API_WITH_BANNER : CONSENT_API,
      id: 'customer-privacy-api',
    },
    'head'
  );

  const { observing, setLoaded } = useApisLoaded({
    withPrivacyBanner,
    onLoaded: onReady,
  });

  const config = useMemo(() => {
    const { checkoutDomain, storefrontAccessToken } = consentConfig;

    return {
      checkoutRootDomain: checkoutDomain,
      storefrontAccessToken,
      storefrontRootDomain: parseStoreDomain(checkoutDomain),
      country: consentConfig.country,
      locale: consentConfig.locale,
    };
  }, [consentConfig, parseStoreDomain]);

  useEffect(() => {
    const consentCollectedHandler = (event) => {
      if (onVisitorConsentCollected) {
        onVisitorConsentCollected(event.detail);
      }
    };

    document.addEventListener(
      'visitorConsentCollected',
      consentCollectedHandler
    );

    return () => {
      document.removeEventListener(
        'visitorConsentCollected',
        consentCollectedHandler
      );
    };
  }, [onVisitorConsentCollected]);

  useEffect(() => {
    if (!withPrivacyBanner || observing.current.privacyBanner) return;
    observing.current.privacyBanner = true;

    let customPrivacyBanner = window.privacyBanner || undefined;

    const privacyBannerWatcher = {
      configurable: true,
      get() {
        return customPrivacyBanner;
      },
      set(value) {
        if (
          typeof value === 'object' &&
          value !== null &&
          'showPreferences' in value &&
          'loadBanner' in value
        ) {
          const privacyBanner = value;

          privacyBanner.loadBanner(config);

          customPrivacyBanner = overridePrivacyBannerMethods({
            privacyBanner,
            config,
          });

          setLoaded.privacyBanner();
          emitCustomerPrivacyApiLoaded();
        }
      },
    };

    Object.defineProperty(window, 'privacyBanner', privacyBannerWatcher);
  }, [
    withPrivacyBanner,
    config,
    overridePrivacyBannerMethods,
    setLoaded.privacyBanner,
  ]);

  useEffect(() => {
    if (observing.current.customerPrivacy) return;
    observing.current.customerPrivacy = true;

    let customCustomerPrivacy = null;
    let customShopify = window.Shopify || undefined;

    Object.defineProperty(window, 'Shopify', {
      configurable: true,
      get() {
        return customShopify;
      },
      set(value) {
        if (
          typeof value === 'object' &&
          value !== null &&
          Object.keys(value).length === 0
        ) {
          customShopify = value;

          Object.defineProperty(window.Shopify, 'customerPrivacy', {
            configurable: true,
            get() {
              return customCustomerPrivacy;
            },
            // eslint-disable-next-line no-shadow
            set(value) {
              if (
                typeof value === 'object' &&
                value !== null &&
                'setTrackingConsent' in value
              ) {
                const customerPrivacy = value;

                customCustomerPrivacy = {
                  ...customerPrivacy,
                  setTrackingConsent: overrideCustomerPrivacySetTrackingConsent(
                    { customerPrivacy, config }
                  ),
                };

                customShopify = {
                  ...customShopify,
                  customerPrivacy: customCustomerPrivacy,
                };

                setLoaded.customerPrivacy();
                emitCustomerPrivacyApiLoaded();
              }
            },
          });
        }
      },
    });
  }, [
    config,
    overrideCustomerPrivacySetTrackingConsent,
    setLoaded.customerPrivacy,
  ]);

  const result = {
    isReady: status === 'ready',
    customerPrivacy: getCustomerPrivacy(),
  };

  if (withPrivacyBanner) {
    result.privacyBanner = getPrivacyBanner();
  }

  return result;
}

import { FueledClientScript } from './FueledClientScript';
import { FueledGTMScript } from './FueledGTMScript';
import { FueledTikTokScript } from './FueledTikTokScript';
import { FueledNorthbeamScript } from './FueledNorthbeamScript';
import { GorgiasChatWidget } from './FueledGorgiasScript';
import { FueledUsableScript } from './FueledUsableScript';
import { FueledKlaviyoScript } from './FueledKlaviyoScript';
import { FueledPostscriptScript } from './FueledPostscriptScript';
import { FueledFondueCashbackScript } from './FueledFondueCashbackScript';
import { FueledKeynesScript } from './FueledKeynesScript';
import { useGetTrueVaultConsent, FueledTrueVault } from './FueledTrueVault';
import { useAnalytics } from './hooks';

const gtmContainerId =
  /* process.env.NEXT_PUBLIC_GTM_CONTAINER_ID || */ 'GTM-P3XTC38C';
const tiktokPixelId =
  process.env.NEXT_PUBLIC_TIKTOK_PUBLIC_API_KEY || 'C18FEQF4RPCPCMC7H62G';
const klaviyoApiKey =
  process.env.NEXT_PUBLIC_KLAVIYO_PUBLIC_API_KEY || 'QC4SUa';
const postScriptShopId = process.env.NEXT_PUBLIC_POSTSCRIPT_SHOP_ID || '398366';
const keynesAdvertiserId =
  process.env.NEXT_PUBLIC_KEYNES_ADVERTISER_ID || 'q39posi';
const keynesUpixelId = process.env.NEXT_PUBLIC_KEYNES_UPIXEL_ID || 'd579yyz';

const scripts = [
  { Component: FueledTrueVault, consent: ['all'] },
  {
    withKey: tiktokPixelId,
    Component: FueledTikTokScript,
    consent: ['marketing'],
  },
  {
    withKey: klaviyoApiKey,
    Component: FueledKlaviyoScript,
    consent: ['marketing'],
  },
  {
    withKey: postScriptShopId,
    Component: FueledPostscriptScript,
    consent: ['marketing'],
  },
  { Component: FueledNorthbeamScript, consent: ['analytics'] },
  { Component: GorgiasChatWidget, consent: ['all'] },
  { Component: FueledUsableScript, consent: ['all'] },
  { Component: FueledFondueCashbackScript, consent: ['all'] },
  {
    withKey: {
      advertiserId: keynesAdvertiserId,
      upixelId: keynesUpixelId,
    },
    Component: FueledKeynesScript,
    consent: ['marketing'],
  },
  {
    withKey: gtmContainerId,
    Component: FueledGTMScript,
    consent: ['all'],
  },
];

export function Fueled() {
  useAnalytics();
  const consent = useGetTrueVaultConsent();

  if (!consent) {
    return (
      <>
        <FueledClientScript consent={consent} />
        <FueledTrueVault />
      </>
    );
  }

  return (
    <>
      <FueledClientScript consent={consent} />
      {scripts
        .filter(
          ({ consent: componentConsent }) =>
            componentConsent.includes('all') ||
            componentConsent.every((c) => consent[c])
        )
        .map(({ withKey, Component, key }, index) => (
          <Component withKey={withKey || ''} key={key || index} />
        ))}
    </>
  );
}

export default Fueled;

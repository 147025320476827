import { useEffect } from 'react';
import { useAnalytics, useScriptLoad, TRACKING_EVENTS } from './hooks';

export function FueledKlaviyoScript({ withKey: klaviyoPixelId }) {
  const { register, subscribe } = useAnalytics();
  const { status } = useScriptLoad(
    {
      id: 'klaviyo-script',
      src: `https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=${klaviyoPixelId}`,
      defer: true,
      waitFor: () => !!window?.klaviyo,
    },
    'head'
  );

  const ready = register('klaviyo');
  /* Specs:
   * https://developers.klaviyo.com/en/docs/javascript_api
   * https://developers.klaviyo.com/en/docs/guide_to_integrating_a_platform_without_a_pre_built_klaviyo_integration
   */

  useEffect(() => {
    const identify = (event) => {
      // Identify a user
      if (event.detail.user_properties?.customer_email) {
        window?.klaviyo.identify({
          email: event.detail.user_properties?.customer_email,
          first_name: event.detail.user_properties?.customer_first_name,
          last_name: event.detail.user_properties?.customer_last_name,
        });
      }
    };

    const viewItemListListener = (event) => {
      identify(event);

      window?.klaviyo.push([
        'track',
        'Category View',
        {
          CategoryName: event.detail.ecommerce.collection_id,
        },
      ]);
    };

    const viewItemListener = (event) => {
      identify(event);

      const [product] = event.detail?.ecommerce?.detail?.products || [];
      if (!product) return;

      window?.klaviyo.push([
        'track',
        'Viewed Product',
        {
          Name: product.name,
          ProductID: product.sku,
          Categories: product.category,
          Collections: product.category,
          ImageURL: product.image,
          URL: document.location.href,
          Brand: product.brand,
          Price: product.price,
          CompareAtPrice: product.price,
        },
      ]);

      window?.klaviyo.push([
        'trackViewedItem',
        {
          Title: product.name,
          ItemId: product.sku,
          Categories: product.category,
          Collections: product.category,
          ImageUrl: product.image,
          Url: document.location.href,
          Metadata: {
            Brand: product.brand,
            Price: product.price,
            CompareAtPrice: product.price,
          },
        },
      ]);
    };

    const addToCartListener = (event) => {
      identify(event);
      const [product] = event.detail?.ecommerce?.add?.products || [];
      if (!product) return;

      const item = {
        Name: product.name,
        ProductID: product.sku,
        ItemId: product.product_id,
        Categories: product.category,
        Collections: product.collections,
        ImageURL: product.image,
        ImageUrl: product.image,
        URL: document.location.href,
        Url: document.location.href,
        Brand: product.brand,
        Price: product.price,
        CompareAtPrice: product.price,
        'Product ID': product.product_id,
        'Product Id': product.product_id,
        product_tags: product.tags,
        product_type: product.category,
        Title: product.name,
        title: product.name,
        'Product Title': product.name,
        'Product Price': product.price,
      };

      window?.klaviyo.push(['track', 'Added to Cart', item]);

      window?.klaviyo.push([
        'trackViewedItem',
        {
          Title: product.name,
          ItemId: product.sku,
          Categories: product.category,
          Collections: product.category,
          ImageUrl: product.image,
          Url: document.location.href,
          Metadata: {
            Brand: product.brand,
            Price: product.price,
            CompareAtPrice: product.price,
          },
        },
      ]);
    };

    const klaviyoFormsListener = (event) => {
      if (event.detail.type === 'submit') {
        window.dataLayer.push({
          event: 'dl_subscribe',
          formId: event.detail.formId,
          formTitle: event.detail.metaData.$source,
        });
      }
    };

    const viewSearchResultsListener = (event) => {
      identify(event);
      const products = event.detail?.ecommerce?.products || [];
      const searchTerm =
        event.detail?.ecommerce?.actionField?.search_term || '';

      window?.klaviyo.push([
        'track',
        'Searched Site',
        {
          SearchTerm: searchTerm,
          ReturnedResults: products.length,
          ReturnedProducts: products.map((p) => ({
            Name: p.name,
            ProductID: p.product_id,
            Price: p.price,
            Categories: p.category,
          })),
        },
      ]);
    };

    const handlePostScriptPopup = (event) => {
      if (event.detail.type === 'formSubmit') {
        const { popupId, popupName } = event.detail;

        // Use a regular expression to find key-value pairs in the popupName
        const keyValuePairs = {};
        const regex = /\[([^\]=]+)=([^\]]+)\]/g;
        let match;
        // eslint-disable-next-line no-cond-assign
        while ((match = regex.exec(popupName)) !== null) {
          // eslint-disable-next-line prefer-destructuring
          keyValuePairs[match[1]] = match[2];
        }

        const identifyObj =
          JSON.stringify(keyValuePairs) !== '{}'
            ? {
                PostscriptPopupId: popupId,
                PostscriptPopupName: popupName,
                ...keyValuePairs,
              }
            : {
                PostscriptPopupId: popupId,
                PostscriptPopupName: popupName,
              };

        window.klaviyo.push(['identify', identifyObj]);
      }
    };

    subscribe(TRACKING_EVENTS.VIEW_ITEM, viewItemListener);
    subscribe(TRACKING_EVENTS.VIEW_ITEM_LIST, viewItemListListener);
    subscribe(TRACKING_EVENTS.ADD_TO_CART, addToCartListener);
    subscribe(TRACKING_EVENTS.VIEW_SEARCH_RESULTS, viewSearchResultsListener);

    window.addEventListener('klaviyoForms', klaviyoFormsListener);
    window.addEventListener('postscriptPopup', handlePostScriptPopup);

    ready();
    return () => {
      window.removeEventListener('klaviyoForms', klaviyoFormsListener);
      window.removeEventListener('postscriptPopup', handlePostScriptPopup);
    };
  }, [status]);

  return null;
}

import Head from 'next/head';

const backpackHeadUserConfig = {
  url: process.env.SITE_URL,
  title: process.env.SITE_SEO_TITLE,
  description: process.env.SITE_DESCRIPTION,
  author: process.env.SITE_AUTHOR,
  locale: process.env.SITE_LOCALE,
  social: {
    twitter: process.env.SOCIAL_TWITTER,
    facebook: process.env.SOCIAL_FACEBOOK,
    instagram: process.env.SOCIAL_INSTAGRAM,
  },
};

export function PageHead({ page = {} }) {
  const { resourceType } = page;

  let pageUrl = '';

  if (resourceType === 'home_page') {
    pageUrl = backpackHeadUserConfig.url;
  } else if (resourceType === 'page') {
    pageUrl = `${backpackHeadUserConfig.url}/pages/${page.handle}`;
  } else if (resourceType === 'product_page') {
    pageUrl = `${backpackHeadUserConfig.url}/products/${page.handle}`;
  } else if (resourceType === 'collection_page') {
    pageUrl = `${backpackHeadUserConfig.url}/collections/${page.handle}`;
  } else if (resourceType === 'article') {
    pageUrl = `${backpackHeadUserConfig.url}/articles/${page.handle}`;
  } else if (resourceType === 'blog') {
    pageUrl = `${backpackHeadUserConfig.url}/blogs/${page.handle}`;
  } else {
    pageUrl = backpackHeadUserConfig.url;
  }

  return (
    <Head>
      <title key="page-title">
        {page?.seo?.title || backpackHeadUserConfig.title}
      </title>
      <meta
        key="page-description"
        name="description"
        content={page?.seo?.description || backpackHeadUserConfig.description}
      />
      <link key="page-canonical-url" rel="canonical" href={pageUrl} />

      <meta name="viewport" content="width=device-width, initial-scale=1" />

      {/* IMPORTANT
            DO NOT NEST THESE INTO SUB-COMPONENTS OR REACT FRAGMENTS
            as next/head doesn't parse nested metas
        */}

      {/* OG and TWIITER CARD IMAGE */}

      {page?.seo?.image ? (
        <>
          <meta
            key="page:fb:image"
            property="og:image"
            content={page.seo.image}
          />
          <meta
            key="page:fb:image:alt"
            property="og:image:alt"
            content={page?.seo?.title || backpackHeadUserConfig.title}
          />
          <meta
            key="page:twitter:image"
            name="twitter:image"
            content={page.seo.image}
          />
        </>
      ) : null}

      {/*
          OpenGraph
            @see: more info https://dev.twitter.com/cards/getting-started
            @see: testing https://cards-dev.twitter.com/validator
        */}

      {process.env.FACEBOOK_APP_ID ? (
        <meta
          key="page:fb:app_id"
          property="fb:app_id"
          content={process.env.FACEBOOK_APP_ID}
        />
      ) : null}

      <meta key="page:fb:url" property="og:url" content={pageUrl} />

      <meta key="page:fb:type" property="og:type" content="website" />

      <meta
        key="page:fb:title"
        property="og:title"
        content={page?.seo?.title || backpackHeadUserConfig.title}
      />

      <meta
        key="page:fb:description"
        property="og:description"
        content={page?.seo?.description || backpackHeadUserConfig.description}
      />

      <meta
        property="og:site_name"
        content={process.env.NEXT_PUBLIC_SITE_TITLE}
      />

      <meta
        key="page:fb:locale"
        property="og:locale"
        content={backpackHeadUserConfig.locale}
      />

      <meta
        key="page:fb:author"
        property="og:author"
        content={backpackHeadUserConfig.author}
      />

      {/*
          TwitterCard
            @see: more info https://dev.twitter.com/cards/getting-started
            @see: testing https://cards-dev.twitter.com/validator
        */}

      <meta key="page:twitter:card" name="twitter:card" content="summary" />

      {backpackHeadUserConfig.social.twitter ? (
        <meta
          key="page:twitter:site"
          name="twitter:site"
          content={backpackHeadUserConfig.social.twitter}
        />
      ) : null}

      {backpackHeadUserConfig.social.twitter ? (
        <meta
          key="page:twitter:creator"
          name="twitter:creator"
          content={backpackHeadUserConfig.social.twitter}
        />
      ) : null}

      <meta key="page:twitter:url" name="twitter:url" content={pageUrl} />

      <meta
        key="page:twitter:title"
        name="twitter:title"
        content={page?.seo?.title || backpackHeadUserConfig.title}
      />

      <meta
        key="page:twitter:description"
        name="twitter:description"
        content={page?.seo?.description || backpackHeadUserConfig.description}
      />

      <meta
        key="page:twitter:image:alt"
        name="twitter:image:alt"
        content={page?.seo?.title || backpackHeadUserConfig.title}
      />

      <meta
        key="default-all-search-engines-crawling"
        name="robots"
        content="index,follow"
      />

      <meta
        key="default-google-engine-crawling"
        name="googlebot"
        content="index,follow"
      />

      <meta
        name="apple-itunes-app"
        content="app-id=1592551241"
        app-argument={pageUrl}
      />

      {/* pwa (progressive web app) */}
      <link rel="manifest" href="/manifest.json" />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/icons/icon-32x32.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="192x192"
        href="/icons/icon-384x384.png"
      />
      <meta
        name="application-name"
        content={process.env.NEXT_PUBLIC_SITE_TITLE}
      />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-status-bar-style" content="default" />
      <meta
        name="apple-mobile-web-app-title"
        content={process.env.NEXT_PUBLIC_SITE_TITLE}
      />
      <meta
        name="theme-color"
        content={process.env.NEXT_PUBLIC_THEME_COLOR || '#000000'}
      />
      <meta name="format-detection" content="telephone=no" />
      <meta name="mobile-web-app-capable" content="yes" />
      <meta
        name="msapplication-TileColor"
        content={process.env.NEXT_PUBLIC_THEME_COLOR || '#000000'}
      />
      <meta name="msapplication-tap-highlight" content="no" />
    </Head>
  );
}

import { section, align } from '@settings/common';
import { color } from '@settings/common/color';

export const Schema = () => {
  return {
    category: 'LoyaltyLion',
    label: 'Loyaltylion Static Rewards List',
    key: 'loyaltylion-static-rewards-list',
    fields: [
      {
        label: 'Enable If',
        name: 'state',
        component: 'radio-group',
        direction: 'horizontal',
        variant: 'radio',
        options: [
          {
            value: 'guest',
            label: 'Signed Out',
          },
          {
            value: 'user',
            label: 'Signed In',
          },
          {
            value: 'enable',
            label: 'Always',
          },
          {
            value: 'userByTag',
            label: 'User By Tag',
          },
          {
            value: 'userWithoutTag',
            label: 'User without Tag',
          },
        ],
        defaultValue: 'enable',
      },
      {
        label: 'User Tag',
        name: 'userTag',
        component: 'textarea',
        defaultValue: 'tier: Platinum',
        description: 'Enter values with each value separated by a comma',
      },
      {
        label: 'Heading',
        name: 'heading',
        component: 'text',
      },
      {
        label: 'Heading Color',
        name: 'headingColor',
        component: 'select',
        options: color.options,
        defaultValue: '#574F4C',
      },
      {
        label: 'SubHeading',
        name: 'subHeading',
        component: 'text',
        defaultValue: '(You need a paid item in your cart to redeem these)',
      },
      {
        label: 'Content alignment (mobile)',
        name: 'alignment',
        component: 'radio-group',
        direction: 'horizontal',
        variant: 'radio',
        options: align.options,
        defaultValue: 'center',
      },
      {
        label: 'Content alignment (desktop)',
        name: 'alignmentDt',
        component: 'radio-group',
        direction: 'horizontal',
        variant: 'radio',
        options: align.options,
        defaultValue: 'center',
      },
      {
        name: 'rewardsList',
        label: 'Rewards List',
        component: 'group-list',
        description: 'List rewards',
        fields: [
          {
            name: 'icon',
            label: 'Icon',
            component: 'image',
          },
          {
            label: 'Title',
            name: 'title',
            component: 'text',
          },
          {
            label: 'SubTitle',
            name: 'subTitle',
            component: 'text',
          },
          {
            label: 'Button Label',
            name: 'buttonText',
            component: 'text',
          },
        ],
      },
      {
        ...section,
        defaultValue: {
          desktop: {
            container: 'container',
            mt: 'none',
            py: 'none',
          },
          mobile: {
            container: 'content',
            mt: 'none',
            py: 'none',
          },
        },
      },
    ],
  };
};

import { useEffect, useCallback, useState, useMemo } from 'react';
import { Container, Grid } from 'theme-ui';
import { useRouter, useSettings } from '@backpackjs/storefront';

import store, {
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from '@store';
import { useDataLayerActions, useLocalStorage } from '@hooks';

import { SearchPageHeader } from './SearchPageHeader';
import { SearchGrid } from './SearchGrid';
import { DesktopFilters, MobileFilters, useSearchFilters } from './Filters';
import { useSearchSort } from './Sort';
import { useSearch } from '../hooks';
import { themed } from './SearchPage.theme';

export const SearchPage = themed(({ theme }) => {
  const [
    {
      pageResults,
      pageSuggested,
      totalPageResults,
      pageSpellCorrection,
      merchandising,
    },
    { getSearchResults, getSearchSuggestions, setSearchPageData },
  ] = useSearch();
  const settings = useSettings();
  const router = useRouter();
  const [{ selectedFilters, filterSummary }, { clearSelectedFilters }] =
    useSearchFilters();
  const [, { sortCollection }] = useSearchSort();
  const { sendViewSearchResultsEvent } = useDataLayerActions();
  const [searchQuery] = useLocalStorage('searchTerm', '');

  const [currentResultsPage, setCurrentResultsPage] = useRecoilState(
    store.searchCurrentResultsPage
  );
  const selectedSort = useRecoilValue(store.searchSelectedSort);
  const setSwatchesMap = useSetRecoilState(store.swatchesMap);
  const [loading, setLoading] = useState(true);
  const [mounted, setMounted] = useState(false);
  const [correctedQueryOverridden, setCorrectedQueryOverridden] =
    useState(false);

  const query = useMemo(() => {
    return searchQuery && searchQuery !== ''
      ? searchQuery
      : router.query?.query || '';
  }, [router.query?.query, searchQuery]);
  const spellcorrect = router.query?.spellcorrect || true;
  const tag = router.query?.tag || '';
  const swatches = settings?.collection?.colors?.swatches || [];

  const isPageAutocomplete = pageSuggested?.type === 'completed';
  const deps = [
    query,
    currentResultsPage,
    selectedSort,
    JSON.stringify(selectedFilters),
    spellcorrect,
    tag,
    mounted,
  ];

  const resetSettingsOnMount = useCallback(() => {
    if (mounted) return;
    setMounted(true);
    setCurrentResultsPage(1);
    clearSelectedFilters();
    sortCollection({ field: null, direction: null });
    setLoading(true);
  }, []);

  // check for search suggestions, then return results
  const getSuggestionsThenResults = useCallback(async () => {
    if (!mounted) return;

    setCorrectedQueryOverridden(spellcorrect === 'false');

    if (!query && !tag) {
      setSearchPageData(null);
      setLoading(false);
      return;
    }
    const data = await getSearchSuggestions({
      query,
      isPage: true,
    });

    let exactTerm = null;
    if (query) {
      exactTerm =
        spellcorrect === 'false'
          ? data?.query
          : data?.['corrected-query'] || data?.query;
    }
    const isAutocomplete = data?.suggested?.type === 'completed';
    const searchTerm = isAutocomplete ? data?.suggested?.text : exactTerm;

    const searchData = await getSearchResults({
      query: searchTerm,
      isAutocomplete,
      isPage: true,
      tag,
      selectedFilters,
    });

    const redirect = searchData?.merchandising?.redirect;

    if (redirect) {
      router.push(redirect);
      return;
    }

    setLoading(false);
  }, [...deps]);

  useEffect(() => {
    resetSettingsOnMount();
  }, []);

  useEffect(() => {
    getSuggestionsThenResults();
  }, [...deps]);

  useEffect(() => {
    return () => {
      setSearchPageData(null);
      setCurrentResultsPage(1);
      clearSelectedFilters();
      sortCollection({ field: null, direction: null });
    };
  }, []);

  useEffect(() => {
    const { origin, pathname, search } = window.location;
    const params = new URLSearchParams(search);
    params.delete('query');
    const updatedUrl = `${origin}${pathname}${
      params.toString() !== '' ? `?${params}` : ''
    }`;

    window.history.replaceState(window.history.state, '', updatedUrl);
  }, [query]);

  useEffect(() => {
    const _swatchesMap = swatches.reduce((map, swatch) => {
      map[swatch.name?.toLowerCase()] = swatch;
      return map;
    }, {});
    setSwatchesMap(_swatchesMap);
  }, [JSON.stringify(swatches)]);

  useEffect(() => {
    sendViewSearchResultsEvent({
      products: pageResults,
      isSearchPage: true,
      searchTerm: query,
    });
  }, [pageResults, sendViewSearchResultsEvent]);

  return (
    <Container data-comp={SearchPage.displayName} sx={theme.wrapper}>
      <SearchPageHeader
        correctedQueryOverridden={correctedQueryOverridden}
        filterSummary={filterSummary}
        isPageAutocomplete={isPageAutocomplete}
        loading={loading}
        pageSpellCorrection={pageSpellCorrection}
        pageSuggested={pageSuggested}
        query={query}
        totalPageResults={totalPageResults}
      />

      <Grid
        sx={{
          gridTemplateColumns: ['1fr', '200px 1fr'],
          columnGap: 6,
          rowGap: 11,
        }}
      >
        <DesktopFilters merchandising={merchandising} />

        <MobileFilters />

        <SearchGrid merchandising={merchandising} searchTerm={query} />
      </Grid>
    </Container>
  );
});

SearchPage.displayName = 'SearchPage';

import { useState, useEffect } from 'react';
import { useScriptLoad, useCustomerPrivacy } from './hooks';

const PUBLIC_TRUE_VAULT_SDK_URL = `https://polaris.truevaultcdn.com/static/pc/UZTLJPM2J/polaris.js`;

export const useGetTrueVaultConsent = () => {
  const [consent, setConsent] = useState(null);

  const { customerPrivacy, isReady } = useCustomerPrivacy({
    storefrontAccessToken: process.env.SHOPIFY_STOREFRONT_API_TOKEN,
    checkoutDomain: process.env.SHOPIFY_CART_DOMAIN || 'shop.brumate.com',
    onVisitorConsentCollected: (newConsent) => {
      console.log('Visitor consent collected:', newConsent);

      setConsent({
        analytics: newConsent.analyticsAllowed,
        marketing: newConsent.marketingAllowed,
        preferences: newConsent.preferencesAllowed,
        sale_of_data: newConsent.saleOfDataAllowed,
      });

      const fueled = window.fueled || {};
      if (typeof window.fueled?.setConsent === 'function') {
        fueled.setConsent({
          analyticsAllowed: newConsent?.analyticsAllowed,
          preferencesAllowed: newConsent?.preferencesAllowed,
          firstPartyMarketingAllowed: newConsent?.marketingAllowed,
          thirdPartyMarketingAllowed: newConsent?.saleOfDataAllowed,
        });
      } else if (typeof window.fueled?.changeConsent === 'function') {
        window.fueled.changeConsent({
          analytics: newConsent?.analyticsAllowed,
          preferences: newConsent?.preferencesAllowed,
          marketing: newConsent?.marketingAllowed,
          saleOfData: newConsent?.saleOfDataAllowed,
        });
      }
    },
  });

  useEffect(() => {
    if (!customerPrivacy?.analyticsProcessingAllowed) return;

    if (!consent) {
      customerPrivacy.setTrackingConsent({
        analytics: customerPrivacy?.analyticsProcessingAllowed?.(),
        marketing: customerPrivacy?.marketingAllowed?.(),
        preferences: customerPrivacy?.preferencesProcessingAllowed?.(),
        sale_of_data: customerPrivacy?.saleOfDataAllowed?.(),
      });
    }
  }, [customerPrivacy?.analyticsProcessingAllowed]);

  return consent;
};

export function FueledTrueVault() {
  const trueVaultSdkUrl =
    process.env.PUBLIC_TRUE_VAULT_SDK_URL || PUBLIC_TRUE_VAULT_SDK_URL;

  useScriptLoad(
    {
      src: trueVaultSdkUrl,
    },
    'head'
  );

  return null;
}

import { Picture } from '@snippets';

import { themed } from './Image.theme';

export const Image = themed(({ theme, image, preload, aboveTheFold }) => {
  if (!image?.imageDt?.src) return null;

  return (
    <>
      {image?.image && (
        <Picture
          data-comp={Image.displayName}
          alt={image?.alt}
          aboveTheFold={aboveTheFold}
          images={[
            {
              src: image?.image?.src,
              width: 768,
              ratio: 1.1,
            },
          ]}
          sx={{
            ...theme.image,
            display: image?.imageDt ? ['block', 'none'] : '',
          }}
          preload={preload}
          width={image?.image?.width}
          height={image?.image?.height}
          imageSx={{
            objectPosition: [image?.position, image?.positionDt],
          }}
        />
      )}
      {image?.imageDt && (
        <Picture
          data-comp={Image.displayName}
          alt={image?.alt}
          aboveTheFold={aboveTheFold}
          images={[
            {
              src: image?.imageDt?.src,
              width: 1024,
              ratio: 1.2,
            },
            {
              src: image?.imageDt?.src,
              width: 1280,
              ratio: 1.2,
            },
            {
              src: image?.imageDt?.src,
              width: 1440,
              ratio: 1.2,
            },
          ]}
          sx={{
            ...theme.image,
            display: image?.image ? ['none', 'block'] : '',
          }}
          preload={preload}
          width={image?.imageDt?.width}
          height={image?.imageDt?.height}
          imageSx={{
            objectPosition: [image?.position, image?.positionDt],
          }}
        />
      )}
    </>
  );
});

Image.displayName = 'Image';
